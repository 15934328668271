<template>
  <div id="rechargeList">
    <div class="table-container">
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="orderId" label="订单编号" align="center">
        </el-table-column>
        <el-table-column prop="platformType" label="支付平台" align="center">
        </el-table-column>
        <el-table-column prop="payAmount" label="支付金额" align="center">
        </el-table-column>
        <el-table-column prop="totalPrice" label="实际金额" align="center">
        </el-table-column>
        <el-table-column label="订单状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus === '订单生成'" class="payStatus"
              >订单生成</span
            >
            <span v-if="scope.row.orderStatus === '待支付'" class="payStatus"
              >待支付</span
            >
            <span
              v-if="scope.row.orderStatus === '支付取消'"
              class="payStatus dot1"
              >支付取消</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              class="payStatus dot1"
              >已退款</span
            >
            <span
              v-if="scope.row.orderStatus === '部分退款'"
              class="payStatus dot1"
              >部分退款</span
            >
            <span
              v-if="scope.row.orderStatus === '支付成功'"
              class="payStatus dot2"
              >支付成功</span
            >
            <span
              v-if="scope.row.orderStatus === '支付失败'"
              class="payStatus dot3"
              >支付失败</span
            >
            <span
              v-if="scope.row.orderStatus === '交易关闭'"
              class="payStatus dot4"
              >交易关闭</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="refundAmount" label="退款金额" align="center">
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/user/charge/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          unionId: this.$route.params.id,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.payTime = item.payTime
              ? this.$moment(item.payTime).format("yyyy-MM-DD HH:mm:ss")
              : "--";
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#rechargeList {
  .table-container {
    .el-table {
      .el-table__body {
        .payStatus {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #8d8d8d;
          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #8d8d8d;
            margin-right: 5px;
          }
        }
        .dot1 {
          color: #f0ad30;
          &::before {
            background-color: #f0ad30;
          }
        }
        .dot2 {
          color: $main-color;
          &::before {
            background-color: $main-color;
          }
        }
        .dot3 {
          color: #ff0000;
          &::before {
            background-color: #ff0000;
          }
        }
        .dot4 {
          color: #a7a2a2;
          &::before {
            background-color: #a7a2a2;
          }
        }
      }
    }
  }
}
</style>
